@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Italianno&display=swap');


body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: 'Inter', sans-serif; /* Set the font family */
  font-size: 1rem; /* Default font size (medium) */

  /* Hide vertical scrollbar while allowing scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
body::-webkit-scrollbar {
  display: none;
}

/* Responsive font size adjustment */
@media (min-width: 768px) {
  body {
    font-size: 1rem; /* Medium font size for small to medium devices */
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 1.125rem; /* Larger font size for larger devices */
  }
}


/* Loader spin animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #7F0741;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container {
  position: relative;
  width: 100%;
  height: 150px; /* Adjust according to your needs */
}

.image-container img {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image-container.loaded img {
  opacity: 1;
}

.image-container.loaded .loader {
  display: none;
}



/* Add this to your main CSS file, e.g., src/App.css */
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
  margin-bottom: 10px; /* Space between spinner and text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

p {
  font-size: 1.2rem;
  margin: 0;
}
